import React, { Fragment, useState } from 'react';

import { Response } from '@campus/commons';
import { useAuthority } from '@campus/auth';
import { Button, Modal, toast } from '@campus/components';

import { useAdminMainContext } from 'providers/AdminMainContextProvider';

import { postImportPedagogicalClassPlan } from 'repositories/IntegrationRepository';

import {
  ButtonArea,
  Card,
  Container,
  Header,
  Item,
  SubTitle,
  Title,
  DescriptionText
} from './styles';

type SelectedItem = {
  value: string;
  name: string;
  action: () => Promise<Response<string>>;
  modalDescription: React.ReactNode;
  modalTitle: string;
} | null;

const TotvsIntegration: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<SelectedItem>(null);
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState(null);

  const { unityName } = useAuthority();
  const { period } = useAdminMainContext();

  const data = [
    {
      value: 'importingClasses',
      name: 'Importação de Aulas',
      action: () => postImportPedagogicalClassPlan(),
      modalTitle: 'Importar aulas',
      modalDescription: (
        <>
          Tem certeza que deseja realizar a Importação de Aulas do&nbsp;
          <strong>{unityName}</strong> do Período&nbsp;
          <strong>{period.name}</strong>?
        </>
      )
    }
  ];

  const handleConfirm = async () => {
    if (loading) return;

    setLoading(true);
    const response = await selectedItem.action();

    if (response.error) {
      toast.error(
        response.error?.message ??
          'Erro ao executar ação, por favor entre em contato com o suporte'
      );
      setLoading(false);
      return;
    }
    setLink(response.data);
    setLoading(false);
  };

  const handleCancel = () => {
    setSelectedItem(null);
    setLink(null);
  };

  return (
    <>
      <Header>
        <Title>Integrações TOTVS</Title>
      </Header>

      <Card>
        <SubTitle>Selecione o tipo de integração que deseja realizar</SubTitle>
        <Container>
          {data.map((item) => {
            return (
              <Fragment key={item.name}>
                <Item>{item.name}</Item>
                <ButtonArea onClick={() => setSelectedItem(item)}>
                  Selecionar
                </ButtonArea>
              </Fragment>
            );
          })}
        </Container>

        {selectedItem ? (
          <Modal show={!!selectedItem} onHide={handleCancel} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{selectedItem.modalTitle}</Modal.Title>
            </Modal.Header>
            {link ? (
              <>
                <Modal.Body>
                  <DescriptionText>
                    Para acompanhar o andamento acesse o link abaixo:
                  </DescriptionText>
                  <a target="_blank" href={link} rel="noreferrer">
                    {link}
                  </a>
                </Modal.Body>
              </>
            ) : (
              <>
                <Modal.Body>
                  <DescriptionText>
                    {selectedItem.modalDescription}
                  </DescriptionText>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="outline-secondary"
                    onClick={handleCancel}
                    disabled={loading}
                  >
                    Cancelar
                  </Button>

                  <Button onClick={handleConfirm} disabled={loading}>
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Processando...
                      </>
                    ) : (
                      'Confirmar'
                    )}
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Modal>
        ) : null}
      </Card>
    </>
  );
};

export default TotvsIntegration;
