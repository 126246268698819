import React, { useState } from 'react';
import { Route } from 'react-router-dom';

import { AppContainer } from '@campus/commons';
import { ContextType } from '@campus/auth';
import { adminTheme } from '@campus/components';

import Menu from 'containers/Menu';
import Content from 'containers/Content';

import Home from 'pages/Home';

import AdminMainContextProvider from 'providers/AdminMainContextProvider';

import { fixedRoutes, Route as RouteType } from './routes';

const App: React.FC = () => {
  const [conditionalRoutes, setConditionalRoutes] = useState<RouteType[]>([]);

  return (
    <AppContainer
      theme={adminTheme}
      contextType={ContextType.ADMIN}
      components={{
        Content: Content,
        MainProvider: AdminMainContextProvider,
        Menu: (props) => (
          <Menu {...props} conditionalRoutes={conditionalRoutes} />
        )
      }}
    >
      <Route
        path=""
        element={
          <Home
            conditionalRoutes={conditionalRoutes}
            setConditionalRoutes={setConditionalRoutes}
          />
        }
      />

      {fixedRoutes.map((route) => (
        <Route
          key={route.path}
          path={`${route.path}/*`}
          element={route.component}
        />
      ))}

      {conditionalRoutes.map((route) => (
        <Route
          key={route.path}
          path={`${route.path}/*`}
          element={route.component}
        />
      ))}
    </AppContainer>
  );
};

export default App;
