import React, { FC, HTMLAttributes } from 'react';

import { Link } from 'react-router-dom';
import { SideMenu } from '@campus/components';

import { fixedRoutes, Route } from 'core/routes';

import MenuHeader from './components/MenuHeader';

type Props = HTMLAttributes<HTMLElement> & {
  conditionalRoutes: Route[];
};

const Menu: FC<Props> = ({ conditionalRoutes, ...props }) => {
  function renderLink(route: Route) {
    return (
      <Link key={route.path} to={route.path}>
        <SideMenu.Option>{route.name}</SideMenu.Option>
      </Link>
    );
  }

  return (
    <SideMenu {...props}>
      <MenuHeader />

      <Link to="">
        <SideMenu.Option>Página inicial</SideMenu.Option>
      </Link>

      {fixedRoutes.map((route) => renderLink(route))}

      {conditionalRoutes.map((route) => renderLink(route))}
    </SideMenu>
  );
};

export default Menu;
