import styled from 'styled-components';

export const Card = styled.div`
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 1rem 1rem;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.gray2};
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: 25px;
  margin: 0;
`;

export const SubTitle = styled.div`
  padding-bottom: 10px;

  font-size: 16px;
  color: ${(props) => props.theme.colors.gray2};
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 85% 15%;
  grid-auto-rows: 40px;
  row-gap: 10px;
`;

export const Item = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-radius: 4px 0px 0px 4px;

  color: ${(props) => props.theme.colors.gray600};
  border: 1px solid ${(props) => props.theme.colors.gray300};
`;

export const ButtonArea = styled(Item)`
  padding-left: 0px;
  border-left: 0px;
  text-align: center;
  justify-content: center;
  border-radius: 0px 4px 4px 0px;

  background-color: ${(props) => props.theme.colors.blue1};
  color: ${(props) => props.theme.colors.white};

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.CSA.blue};
  }
`;

export const DescriptionText = styled.div`
  margin: 10px 0px 5px;
`;
