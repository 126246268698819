import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { createCondicionalRoutes, fixedRoutes, Route } from 'core/routes';

import {
  Header,
  Title,
  Container,
  CardText,
  CardLink,
  IconContainer
} from './styles';

type Props = {
  conditionalRoutes: Route[];
  setConditionalRoutes: Dispatch<SetStateAction<Route[]>>;
};

const Home: React.FC<Props> = ({ conditionalRoutes, setConditionalRoutes }) => {
  useEffect(() => {
    createCondicionalRoutes().then((routes) => {
      setConditionalRoutes(routes);
    });
  }, [setConditionalRoutes]);

  function renderLink(route: Route) {
    return (
      <CardLink key={route.path} to={route.path}>
        <CardText>{route.name}</CardText>
        <IconContainer>
          <route.Icon color="#fff" size={50} />
        </IconContainer>
        <CardText>{route.description}</CardText>
      </CardLink>
    );
  }

  return (
    <>
      <Header>
        <Title>Home</Title>
      </Header>
      <Container>
        {fixedRoutes.map((route) => {
          return route.path === '' ? null : renderLink(route);
        })}

        {conditionalRoutes.map((route) => {
          return route.path === '' ? null : renderLink(route);
        })}
      </Container>
    </>
  );
};

export default Home;
