import { Api } from '@campus/auth';
import { Response } from '@campus/commons';

export async function postImportPedagogicalClassPlan(): Promise<
  Response<string>
> {
  try {
    const response = await Api.INSTANCE.getApi().post(
      'integration/admin/import-pedagogical-planning-class-plan'
    );

    const data = response.data;

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
}
